export const darkTheme = {
    bg: "#0F1219",
    bgLight: "#171B24",
    primary: "#5B6EE1",
    text_primary: "#FFFFFF",
    text_secondary: "#B4BDDB",
    text_skills: "#9BA5CC",
    card: "#1A1F2B",
    card_light: "#202634",
    button: "#5B6EE1",
    white: "#FFFFFF",
    black: "#000000",
    colored_detail: "#FFFFFF",
    highlighted_svg: "#5B6EE1",
    buttonGradient: "linear-gradient(180deg, #5B6EE1 0%, #4557C0 100%)"
};

export const lightTheme = {
    bg: "#F8F9FC",
    bgLight: "#FFFFFF",
    primary: "#5B6EE1",
    text_primary: "#1A1F2B",
    text_secondary: "#4A5571",
    text_skills: "#566388",
    card: "#FFFFFF",
    card_light: "#F0F2F8",
    button: "#5B6EE1",
    white: "#FFFFFF",
    black: "#000000",
    colored_detail: "#000000",
    highlighted_svg: "#5B6EE1",
    buttonGradient: "linear-gradient(180deg, #6576E4 0%, #5B6EE1 100%)"
};

