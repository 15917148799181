import React from 'react';

const ThemeToggleIcon = () => (
    <svg
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M12 4V2M12 22v-2M6.34 6.34L4.93 4.93M19.07 19.07l-1.41-1.41M4 12H2M22 12h-2M6.34 17.66l-1.41 1.41M19.07 4.93l-1.41 1.41M17 12a5 5 0 11-10 0 5 5 0 0110 0z"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default ThemeToggleIcon; 